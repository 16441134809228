<template>
  <div></div>
</template>

<script>
export default {
  name: "Storage",
  mounted() {
    if (
      this.$route.path ===
        "/storage/_Pages/Magnum_Club/91b9c206-40af-4c9b-9a29-5d8f208d7387.pdf" ||
      this.$route.path ===
        "/storage/uploads/Magnum%20Club/91b9c206-40af-4c9b-9a29-5d8f208d7387.pdf"
    ) {
      window.location.href =
        "https://magnum.kz:1337/uploads/Pravila_programmy_loyalnosti_Magnum_Club_839134a497.pdf?updated_at=2022-04-22T05:24:27.110Z";
    }
  },
};
</script>

<style lang="scss" scoped></style>
